import { render, staticRenderFns } from "./thank-you.vue?vue&type=template&id=77a8177b&scoped=true&"
import script from "./thank-you.vue?vue&type=script&lang=ts&"
export * from "./thank-you.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "77a8177b",
  null
  
)

export default component.exports